import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { withPrefix } from "gatsby-link"
import { categories } from "../data/services"
import softwares_uri from "../data/softwares"
import { useIntl } from "gatsby-plugin-react-intl"
import { myContext } from "../provider"

/* Random alphanumeric name with 10 chars */
const name = () =>
  [...Array(10)]
    .map(() => Math.random().toString(36)[3])
    .join("")
    .replace(/(.|$)/g, (c) =>
      c[!Math.round(Math.random()) ? "toString" : "toLowerCase"]()
    )

const buildEndpoint = (chaton) => {
  const hostname = new URL(chaton.endpoint).hostname
  if (chaton.software === "Etherpad") {
    if (chaton.chaton === "Framasoft") {
      return "https://bimestriel.framapad.org/p/"
    }
    return `https://${hostname}/p/`
  }
  return `https://${hostname}/`
}

export const ServiceCard = ({ serviceKey: key, services }) => {
  const initialName = name()
  const [instanceName, setInstanceName] = useState(initialName)

  const prefix = Math.trunc(new Date().getTime() / 3600000).toString(36)

  const regenerateName = () => {
    if (instanceName === "") {
      setInstanceName(name())
    }
  }
  const createInstance = (chaton) => {
    window.location = `${buildEndpoint(chaton)}${prefix}${instanceName}`
  }
  const intl = useIntl()

  const [isClient, setClient] = useState(false)
  useEffect(() => {
    setClient(true)
  }, [])
  const someKey = isClient ? `client` : `server`

  return (
    <myContext.Consumer>
      {(context) => (
        <React.Fragment>
          <Card>
            <Card.Body>
              {key in categories && (
                <>
                  <div className="d-flex media">
                    <div className="flex-shrink-0">
                      <img
                        width={90}
                        height={90}
                        className="mr-3"
                        src={withPrefix(`/pictures/${categories[key]["icon"]}`)}
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1 ms-3 media-body">
                      <Card.Title role="heading" aria-level="2">
                        {intl.formatMessage({ id: categories[key]["title"] })}
                      </Card.Title>
                      <Card.Text>
                        {intl.formatMessage({
                          id: categories[key]["description"],
                        })}
                      </Card.Text>
                    </div>
                  </div>
                  <hr role="presentation" />
                  <Row
                    className="justify-content-between"
                    style={{ marginBottom: "1rem" }}
                  >
                    <Col>
                      <Card.Text className="provided-by">
                        {services[key].length > 1 && (
                          <OverlayTrigger
                            key="trigger{key}"
                            placement="top"
                            overlay={
                              <Tooltip
                                id={`change${key}Instance`.replace(/\s+/g, "")}
                              >
                                {intl.formatMessage({
                                  id: "service.changeInstance",
                                })}
                              </Tooltip>
                            }
                          >
                            <button
                              onClick={() =>
                                context.changeInstanceForService(key)
                              }
                              type={"button"}
                              className="btn refresh"
                              aria-label={intl.formatMessage({
                                id: "service.changeInstance",
                              })}
                              aria-controls={`display${key}Instance`.replace(
                                /\s+/g,
                                ""
                              )}
                            >
                              <img
                                src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' class='feather feather-refresh-ccw'%3e%3cpolyline points='1 4 1 10 7 10'%3e%3c/polyline%3e%3cpolyline points='23 20 23 14 17 14'%3e%3c/polyline%3e%3cpath d='M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15'%3e%3c/path%3e%3c/svg%3e"
                                alt=""
                              />
                            </button>
                          </OverlayTrigger>
                        )}
                        <span
                          id={`display${key}Instance`.replace(/\s+/g, "")}
                          aria-live="polite"
                        >
                          {intl.formatMessage({ id: "service.instance" })}{" "}
                          <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href={
                              softwares_uri[
                                context.instanceForService[key].software
                              ]
                            }
                          >
                            {context.instanceForService[key].software}
                          </a>
                          <br />
                          {intl.formatMessage({
                            id: "service.providedBy",
                          })}{" "}
                          <a
                            style={{
                              whiteSpace: "nowrap",
                              maxWidth: "calc(100% - 120px)",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "inline-block",
                              verticalAlign: "bottom",
                            }}
                            href={
                              context.instanceForService[key].chaton_website
                            }
                          >
                            {context.instanceForService[key].chaton}
                          </a>
                        </span>
                      </Card.Text>
                    </Col>
                  </Row>
                  {categories[key].direct ? (
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault()
                        createInstance(context.instanceForService[key])
                      }}
                    >
                      <InputGroup className="mb-3">
                        <FormControl
                          placeholder={
                            intl.formatMessage({
                              id: categories[key]["placeholder"],
                            }) +
                            " (" +
                            intl.formatMessage({ id: "service.optional" }) +
                            ")"
                          }
                          aria-label={intl.formatMessage({
                            id: categories[key]["placeholder"],
                          })}
                          aria-required="false"
                          onChange={(e) => setInstanceName(e.target.value)}
                          onBlur={() => regenerateName()}
                          style={{ fontSize: "0.9rem" }}
                        />
                        <Button variant="primary" type={"submit"}>
                          {intl.formatMessage({ id: "service.create" })}
                        </Button>
                      </InputGroup>
                    </Form>
                  ) : (
                    <>
                      <a
                        key={someKey}
                        href={context.instanceForService[key].endpoint}
                        className="btn btn-primary"
                        role="button"
                      >
                        {intl.formatMessage({
                          id: categories[key]["placeholder"],
                        })}
                      </a>
                    </>
                  )}
                  <a
                    href={categories[key]["wiki"]}
                    className="btn btn-outline-secondary"
                    role="button"
                  >
                    {intl.formatMessage({ id: "service.other_instances" })}
                  </a>
                </>
              )}
            </Card.Body>
          </Card>
        </React.Fragment>
      )}
    </myContext.Consumer>
  )
}
