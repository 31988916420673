import { useIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/global.scss"
import { Col, Row } from "react-bootstrap"
import React from "react"
import { ServiceCard } from "../components/service_card"
import { categoriesOrder } from "../data/services"

const Funct = ({
  data: {
    allChatonsServicesJson: { edges },
  },
}) => {
  //TODO link with the true json data
  //TODO check a11y
  //TODO finalize i18n IN PROGRESS

  const services = edges.reduce((acc, { node }) => {
    node.category = node.type.split(" (")[0]
    node.URL_site = node.chaton_website
    const servicesExistants = acc[node.category] || []
    servicesExistants.push(node)
    acc[node.category] = servicesExistants
    return acc
  }, {})

  const intl = useIntl()
  //TODO create a component ServiceCard
  return (
    <Layout>
      <Seo
        lang={intl.locale}
        title={intl.formatMessage({ id: "home.title" })}
        keywords={[
          `chatons`,
          `chaton`,
          `logiciel`,
          `libres`,
          `service`,
          `collectif`,
          `hebergeur`,
          `alternatif`,
          `transparent`,
          `ouvert`,
          `neutre`,
          `solidaire`,
        ]}
      />
      <Row className="justify-content-md-center">
        {categoriesOrder.map((key) => (
          <Col key={key} sm={"12"} md={"6"} lg={"6"} xl={"4"}>
            <ServiceCard services={services} serviceKey={key} />
          </Col>
        ))}
      </Row>
    </Layout>
  )
}

export default Funct

export const query = graphql`
  query {
    allChatonsServicesJson(
      filter: { consent: { eq: "1" }, access_type: { eq: "Ouvert à tou⋅te⋅s" } }
    ) {
      edges {
        node {
          chaton
          chaton_website
          edit_link
          endpoint
          software
          type
        }
      }
    }
  }
`
